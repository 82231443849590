import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import { router } from '@/router/router'
import { i18nTTM } from '@/services/i18n-ttm'
import VueCookies from 'vue-cookies'
import Vue3TouchEvents from 'vue3-touch-events'
import InlineSvg from 'vue-inline-svg'

createApp(App)
    .use(createPinia())
    .use(router)
    .use(VueCookies, { expire: '1000d' })
    .use(Vue3TouchEvents, {
        disableClick: false,
        touchClass: '',
        tapTolerance: 10,
        touchHoldTolerance: 400,
        swipeTolerance: 100,
        longTapTimeInterval: 400
    })
    .use(i18nTTM)
    .component('inline-svg', InlineSvg)
    .mount('#app')
