import { createRouter, createWebHashHistory } from 'vue-router'
import { paths } from '@/router/paths'
import MonitorPage from '@/pages/monitor.vue'
import SettingsPage from '@/pages/settings.vue'
import StatisticsPage from '@/pages/statistics.vue'
import CrossingPage from '@/pages/crossing.vue'
import HelpPage from '@/pages/help.vue'
import DataManagmentPage from '@/pages/data-managment.vue'

const routes = [
    {
        path: paths.HOME,
        name: 'home',
        component: MonitorPage
    },
    {
        path: paths.MONITOR,
        name: 'monitor',
        component: MonitorPage
    },
    {
        path: paths.SETTINGS,
        name: 'settings',
        component: SettingsPage
    },
    {
        path: paths.STATISTICS,
        name: 'statistics',
        component: StatisticsPage
    },
    {
        path: paths.CROSSING,
        name: 'crossing',
        component: CrossingPage
    },
    {
        path: paths.HELP,
        name: 'help',
        component: HelpPage
    },
    {
        path: paths.DATAMANAGMENT,
        name: 'data-managment',
        component: DataManagmentPage
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach(async (to, from, next) => {
    let bOk = false
    for (const r of routes) {
        if (r.path === to.path) {
            bOk = true
            break
        } else if (to.path.startsWith('/crossing/')) {
            const parts = to.path.split('/')
            if (parts.length && parts[parts.length - 1] && parts[parts.length - 1] !== 'crossing') {
                bOk = true
                break
            }
        }
    }

    if (bOk) {
        next()
    } else {
        next(paths.HOME)
    }
})

export { router }
