import _, { Dictionary } from 'lodash'

/* eslint-disable @typescript-eslint/no-explicit-any */

const dt12H = {
    short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    },
    long: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric'
    }
}

const dt24H = {
    short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    },
    long: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false
    }
}

export const dateTimeFormats = {
    cs: _.cloneDeep(dt24H),
    da: _.cloneDeep(dt24H),
    de: _.cloneDeep(dt24H),
    en: _.cloneDeep(dt12H),
    es: _.cloneDeep(dt24H),
    fi: _.cloneDeep(dt24H),
    fr: _.cloneDeep(dt24H),
    it: _.cloneDeep(dt24H),
    ja: _.cloneDeep(dt24H),
    lv: _.cloneDeep(dt24H),
    nl: _.cloneDeep(dt24H),
    no: _.cloneDeep(dt24H),
    pl: _.cloneDeep(dt24H),
    pt: _.cloneDeep(dt24H),
    ru: _.cloneDeep(dt24H),
    sv: _.cloneDeep(dt24H),
    tr: _.cloneDeep(dt24H),
    zh: _.cloneDeep(dt24H)
}

export const locales: {[index: string]:any} = {
    cs: 'CS - Česky',
    da: 'DA - Dansk',
    de: 'DE - Deutsch',
    en: 'EN - English',
    es: 'ES - Español',
    fi: 'FI - Suomi',
    fr: 'FR - Français',
    it: 'IT - Italiano',
    ja: 'JA - 日本人',
    lv: 'LV - Latviešu Valoda',
    nl: 'NL - Nederlands',
    no: 'NO - Norsk',
    pl: 'PL - Polski',
    pt: 'PT - Português',
    ru: 'RU - Русский',
    sv: 'SV - Svenska',
    tr: 'TR - Türkçe',
    zh: 'ZH - 中文'
}

export const localesRegion: {[index: string]:any} = {
    cs: ['cs-CZ'],
    da: ['da-DK'],
    de: ['de-DE', 'de-AT', 'de-CH'],
    en: ['en-GB', 'en-AU', 'en-CA', 'en-US'],
    es: ['es-ES'],
    fi: ['fi-FI'],
    fr: ['fr-FR', 'fr-BE', 'fr-CA', 'fr-CH'],
    it: ['it-IT', 'it-CH'],
    ja: ['ja-JP'],
    lv: ['lv-LV'],
    nl: ['nl-NL'],
    no: ['no-NO'],
    pl: ['pl-PL'],
    pt: ['pt-PT', 'pt-BR'],
    ru: ['ru-RU'],
    sv: ['sv-SE'],
    tr: ['tr-TR'],
    zh: ['zh-CN', 'zh-TW']
}

export function wifiCountrys (): Dictionary<string> {
    const countryCodes: Dictionary<string> = {}
    countryCodes.AE = 'United Arab Emirates'
    countryCodes.AR = 'Argentina'
    countryCodes.AT = 'Austria'
    countryCodes.AU = 'Australia'
    countryCodes.BG = 'Bulgaria'
    countryCodes.BH = 'Bahrain'
    countryCodes.BM = 'Bermuda'
    countryCodes.BO = 'Bolivia'
    countryCodes.BR = 'Brazil'
    countryCodes.CA = 'Canada'
    countryCodes.CH = 'Switzerland'
    countryCodes.CL = 'Chile'
    countryCodes.CN = 'China'
    countryCodes.CO = 'Colombia'
    countryCodes.CR = 'Costa Rica'
    countryCodes.CS = 'Serbia and Montenegro'
    countryCodes.CY = 'Cyprus'
    countryCodes.CZ = 'Czech Republic'
    countryCodes.DE = 'Germany'
    countryCodes.DK = 'Denmark'
    countryCodes.DO = 'Dominican Republic'
    countryCodes.DZ = 'Algeria'
    countryCodes.EC = 'Ecuador'
    countryCodes.EE = 'Estonia'
    countryCodes.EG = 'Egypt'
    countryCodes.ES = 'Spain'
    countryCodes.FI = 'Finland'
    countryCodes.FR = 'France'
    countryCodes.GB = 'United Kingdom'
    countryCodes.GR = 'Greece'
    countryCodes.GT = 'Guatemala'
    countryCodes.HK = 'Hong Kong'
    countryCodes.HN = 'Honduras'
    countryCodes.ID = 'Indonesia'
    countryCodes.IE = 'Ireland'
    countryCodes.IL = 'Israel'
    countryCodes.IN = 'India'
    countryCodes.IS = 'Iceland'
    countryCodes.IT = 'Italy'
    countryCodes.JM = 'Jamaica'
    countryCodes.JO = 'Jordan'
    countryCodes.JP = 'Japan'
    countryCodes.KE = 'Kenya'
    countryCodes.KR = 'Republic of Korea (South Korea)'
    countryCodes.KW = 'Kuwait'
    countryCodes.LB = 'Lebanon'
    countryCodes.LI = 'Liechtenstein'
    countryCodes.LK = 'Sri Lanka'
    countryCodes.LT = 'Lithuania'
    countryCodes.LU = 'Luxembourg'
    countryCodes.MA = 'Morocco'
    countryCodes.MU = 'Mauritius'
    countryCodes.MX = 'Mexico'
    countryCodes.NL = 'Netherlands'
    countryCodes.NO = 'Norway'
    countryCodes.NZ = 'New Zealand'
    countryCodes.OM = 'Oman'
    countryCodes.PA = 'Panama'
    countryCodes.PE = 'Peru'
    countryCodes.PH = 'Philippines'
    countryCodes.PK = 'Islamic Republic of Pakistan'
    countryCodes.PL = 'Poland'
    countryCodes.PR = 'Puerto Rico'
    countryCodes.PT = 'Portugal'
    countryCodes.QA = 'Qatar'
    countryCodes.RO = 'Romania'
    countryCodes.RU = 'Russia'
    countryCodes.SA = 'Saudi Arabia'
    countryCodes.SG = 'Singapore'
    countryCodes.SI = 'Slovenia'
    countryCodes.SK = 'Slovak Republic'
    countryCodes.SV = 'El Salvador'
    countryCodes.TH = 'Thailand'
    countryCodes.TN = 'Tunisia'
    countryCodes.TR = 'Turkey'
    countryCodes.TT = 'Trinidad and Tobago'
    countryCodes.TW = 'Taiwan'
    countryCodes.UA = 'Ukraine'
    countryCodes.US = 'United States'
    countryCodes.UY = 'Uruguay'
    countryCodes.VE = 'Venezuela'
    countryCodes.VN = 'Vietnam'
    countryCodes.ZA = 'South Africa'
    return countryCodes
}
