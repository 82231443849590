import { defineStore } from 'pinia'
import { IVersion } from '@/services/network-if'

export const useTTMStore = defineStore('ttmStore', {
    state: () => ({
        networkChanged: false,
        brand: 'beissbarth.png',
        proxyVersion: { version: '', platform: '' } as IVersion,
        axiswise: false,
        rebootInProgress: false,
        updateInProgress: false
    }),

    getters: {
        networkChangedStatus (state): boolean {
            return state.networkChanged
        },
        brandValue (state): string {
            return state.brand
        },
        versionInfo (state): IVersion {
            return state.proxyVersion
        },
        axiswiseEnabled (state): boolean {
            return state.axiswise
        },
        getRebootStatus (state): boolean {
            return state.rebootInProgress
        },
        getUpdateStatus (state): boolean {
            return state.updateInProgress
        }
    },

    actions: {
        setNetworkStatus (status: boolean) {
            this.networkChanged = status
        },
        setBrand (data: string) {
            this.brand = data
        },
        setVersion (version: string, platform: string) {
            this.proxyVersion.version = version
            this.proxyVersion.platform = platform
        },
        setRebootStatus (inProgress: boolean) {
            this.rebootInProgress = inProgress
        },
        setUpdateStatus (inProgress: boolean) {
            this.updateInProgress = inProgress
        }
    }
})
