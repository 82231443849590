import { i18nTTM } from '@/services/i18n-ttm'

export interface ICrossingError {
    id: number;
    tire: string;
    module: string;
    codeStr: string;
    message: string;
    extraInfo: string;
    category: number;
}

export class CrossingErrors {
    public decode (code: number, info: string): ICrossingError {
        const crossingError: ICrossingError = {
            id: 0,
            tire: '',
            module: '',
            codeStr: '',
            message: '',
            extraInfo: info,
            category: 0
        }

        try {
            const errorCodeHex = code.toString(16)
            const errorCodeHexlo32 = errorCodeHex.substring(errorCodeHex.length - 8)
            const errorCodeHexhi32 = errorCodeHex.substring(0, errorCodeHex.length - 8)
            const errorCodeLo32 = parseInt(errorCodeHexlo32, 16)
            const errorCodeHi32 = parseInt(errorCodeHexhi32, 16)
            const tirepos = (errorCodeHi32 & 0xF00000) >> 20
            const module = (errorCodeHi32 & 0x0F0000) >> 16

            crossingError.id = errorCodeLo32 & 0xFFFF
            crossingError.category = errorCodeHi32 & 0xFFFF
            crossingError.codeStr = CrossingErrors.hexstr(code)

            const idStr = `${crossingError.id < 10000 ? 'E0' : 'E'}${crossingError.id.toString()}`
            crossingError.message = CrossingErrors.getTr(`errors.${idStr}`)

            if ((tirepos & 8) > 0) {
                crossingError.tire = CrossingErrors.getTr('FLTire')
            }
            if ((tirepos & 4) > 0) {
                crossingError.tire += `${crossingError.tire ? ',' : ''}${CrossingErrors.getTr('FRTire')}`
            }
            if ((tirepos & 2) > 0) {
                crossingError.tire += `${crossingError.tire ? ',' : ''}${CrossingErrors.getTr('RLTire')}`
            }
            if ((tirepos & 1) > 0) {
                crossingError.tire += `${crossingError.tire ? ',' : ''}${CrossingErrors.getTr('RRTire')}`
            }

            if (module === 1) {
                crossingError.module = `${CrossingErrors.getTr('setup.master')}, ${CrossingErrors.getTr('setup.satellite')}`
            } else if (module === 2) {
                crossingError.module = CrossingErrors.getTr('setup.master')
            } else if (module === 3) {
                crossingError.module = CrossingErrors.getTr('setup.satellite')
            }
        } catch (e) {
            // PARSING ERROR CODE FAILED
        }

        return crossingError
    }

    private static getTr (id: string): string {
        return i18nTTM.global.t(id) as string
    }

    private static hexstr (value: number): string {
        return `0x${value.toString(16).toUpperCase()}`
    }
}
