import axios from 'axios'
import { ESocketStatus, ISocketMessage, IVersion } from '@/services/network-if'
import { useTTMStore } from '@/services/store'

/* eslint-disable @typescript-eslint/no-explicit-any */

class Network {
    ws: WebSocket | null = null;
    readonly checkTimeout = 2000;
    readonly maxCounter = 4;
    connectionTimer = 0;
    notificationTimer = 0;
    wsCallback: { (data: string): void; } [] = [];

    startWebsocket () {
        try {
            if (window.location.protocol.toUpperCase().indexOf('HTTPS') !== (-1)) {
                this.ws = new WebSocket(`wss://${window.location.host}/api`)
            } else {
                this.ws = new WebSocket(`ws://${window.location.host}/api`)
            }
        } catch (e) {
            this.ws = null
        }

        if (this.ws) {
            this.ws.onmessage = (msg) => {
                this.clearCheckTimer()
                this.clearNotificationTimer()
                const store = useTTMStore()

                if (msg.data === 'PONG') {
                    if (store.getUpdateStatus) {
                        store.setUpdateStatus(false)
                        window.location.reload()
                    }
                } else if (msg.data === 'UPDATE') {
                    store.setUpdateStatus(true)
                } else {
                    const msgData = JSON.parse(msg.data) as unknown as ISocketMessage

                    if (msgData.status === ESocketStatus.UPDATE_IN_PROGRESS) {
                        store.setUpdateStatus(true)
                    } else if (msgData.status === ESocketStatus.SETUP_CANCELLED) {
                        store.setUpdateStatus(false)
                    } else if (msgData.status === ESocketStatus.REBOOT_IN_PROGRESS) {
                        store.setRebootStatus(true)
                        this.wsCallback.forEach(cb => cb(msg.data))
                    } else {
                        this.wsCallback.forEach(cb => cb(msg.data))
                    }
                }
                this.connectionTimer = setTimeout(this.checkConnection.bind(this), this.checkTimeout)
            }

            this.ws.onclose = async () => {
                this.ws = null
            }

            this.ws.onopen = () => {
                this.clearNotificationTimer()
                const store = useTTMStore()
                store.setRebootStatus(false)
                this.sendConnectionStatus(ESocketStatus.CONNECTION_OPENED)
            }

            this.ws.onerror = async () => {
                this.socketClose()
            }
        }

        this.connectionTimer = setTimeout(this.checkConnection.bind(this), this.checkTimeout)
    }

    async checkConnection () {
        if (this.ws && this.ws.readyState === WebSocket.OPEN) {
            this.ws.send('PING')
            this.connectionTimer = setTimeout(this.checkConnection.bind(this), this.checkTimeout)
        } else {
            this.clearCheckTimer()

            this.socketClose()
            setTimeout(() => { this.startWebsocket() }, this.checkTimeout / 10)

            if (this.notificationTimer === 0) {
                this.notificationTimer = setTimeout(() => {
                    this.sendConnectionStatus(ESocketStatus.CONNECTION_LOST)
                }, this.checkTimeout * 5)
            }
        }
    }

    socketClose () {
        if (this.ws) {
            this.ws.close()
            this.ws = null
        }
    }

    clearCheckTimer () {
        if (this.connectionTimer !== 0) {
            clearTimeout(this.connectionTimer)
            this.connectionTimer = 0
        }
    }

    clearNotificationTimer () {
        if (this.notificationTimer !== 0) {
            clearTimeout(this.notificationTimer)
            this.notificationTimer = 0
        }
    }

    setWebsocketCallback (wsCallback: { (data: string):void; }) {
        this.wsCallback.push(wsCallback)
    }

    async apiRequest (url: string) {
        try {
            const client = axios.create()
            client.defaults.timeout = 5000
            const result = await client.get(url)
            return result.data
        } catch (e) {
            return null
        }
    }

    async apiPost (url: string, data: any) {
        try {
            const client = axios.create()
            client.defaults.timeout = 5000
            const result = await client.post(url, data, { headers: { 'Content-Type': 'application/json' } })
            return result.data
        } catch (e) {
            return null
        }
    }

    async apiUpload (url: string, formData: FormData) {
        try {
            const client = axios.create()
            client.defaults.timeout = 600000
            return await client.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        } catch (e) {
            return null
        }
    }

    async proxyVersion () {
        const store = useTTMStore()
        if (!store.versionInfo.version) {
            const url = 'api/about'
            const result = await this.apiRequest(url)

            if (result) {
                const version = result as unknown as IVersion
                store.setVersion(version.version, version.platform)
                return version
            } else {
                return null
            }
        } else {
            return { version: store.versionInfo.version, platform: store.versionInfo.platform } as IVersion
        }
    }

    sendConnectionStatus (status: ESocketStatus) {
        const response: ISocketMessage = {
            status,
            data: { }
        }
        this.wsCallback.forEach(cb => cb(JSON.stringify(response)))
    }
}

const network = new Network()
export { network }
