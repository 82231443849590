import { dateTimeFormats } from '@/i18n'
import { createI18n } from 'vue-i18n'
import cs from '@/locales/cs.json'
import da from '@/locales/da.json'
import de from '@/locales/de.json'
import en from '@/locales/en.json'
import es from '@/locales/es.json'
import fi from '@/locales/fi.json'
import fr from '@/locales/fr.json'
import it from '@/locales/it.json'
import ja from '@/locales/ja.json'
import lv from '@/locales/lv.json'
import nl from '@/locales/nl.json'
import no from '@/locales/no.json'
import pl from '@/locales/pl.json'
import pt from '@/locales/pt.json'
import ru from '@/locales/ru.json'
import sv from '@/locales/sv.json'
import tr from '@/locales/tr.json'
import zh from '@/locales/zh.json'

export type localeType = 'cs' | 'da' | 'de' | 'en' | 'es' | 'fi' | 'fr' | 'it' | 'ja' | 'lv' | 'nl' | 'no' | 'pl'| 'pt' | 'ru' | 'sv' | 'tr' | 'zh';

export const i18nTTM = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: { cs, da, de, en, es, fi, fr, it, ja, lv, nl, no, pl, pt, ru, sv, tr, zh },
    dateTimeFormats
})

export const setLocale = (locale: localeType) => {
    i18nTTM.global.locale = locale
}

export const getLocale = () => {
    return i18nTTM.global.locale
}
