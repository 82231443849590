import { IQueryData } from '@/services/query-api-if'

export enum EHint {
    None,
    WheelAlignment,
    TirePressure,
    All
}

export enum ELaneStatus {
    IDLE,
    BUSY,
    CONNECTED,
    DISCONNECTED,
    ERROR,
    DATA,
    SINGLE_AXLE_DATA
}

export interface IMonitorTread {
    value: number;
    rating: string;
    deepGroove: boolean;
}

export interface IMonitorRecord {
    timestamp: string;
    measplace: string;
    customer: string;
    vehicle: string;
    frontLeft: IMonitorTread;
    frontRight: IMonitorTread;
    rearLeft: IMonitorTread;
    rearRight: IMonitorTread;
    vehicleRating: string;
    crossingDirection: string;
    hint: EHint;
    partial: boolean;
}

export interface ILaneData {
    status: ELaneStatus;
    statusCode: number;
    statusInfo: string;
    data: IQueryData | null;
    ipAddr: string;
}

export interface ICrossingRecord {
    timestamp: string;
    queryData: IQueryData;
    measplace: string;
    customer: string;
    vehicle: string;
    partial: boolean;
}

export type statusClass = {
    red: boolean,
    green: boolean,
    yellow: boolean,
    black: boolean
};

export type statusStdClass = {
    neutral: boolean,
    neutrallite: boolean,
    good: boolean,
    marginal: boolean,
    replace: boolean
}

export interface IProxyAbout {
    version: string;
    platform: string;
    datetime: string;
    tzOffset: string;
    raspberrypi?: {
        defaultUrl: string;
        hostname: string;
        osversion: string;
    };
}
