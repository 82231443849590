const paths = {
    HOME: '/',
    MONITOR: '/monitor',
    SETTINGS: '/settings',
    STATISTICS: '/statistics',
    CROSSING: '/crossing/:lane',
    HELP: '/help',
    DATAMANAGMENT: '/data-mamangment'
}

Object.freeze(paths)
export { paths }
